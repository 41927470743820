import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Heading, Link, Paragraph } from '~storybook/blocks/typography/index'
//

export const SearchResults = ({ results }) => {
  return Array.isArray(results) && results?.length > 0 ? (
    <div>
      <Heading level="h2" className="mb-6">
        <FormattedMessage id="search.results" values={{ total: results?.length }} />
      </Heading>

      {results?.map((result, idx) => (
        <div key={`result-${result.nid}-${idx}`} className="mb-4">
          <Link to={result.path}>{result.title}</Link>
        </div>
      ))}
    </div>
  ) : (
    <Paragraph>
      <FormattedMessage id="search.results" values={{ total: 0 }} />
    </Paragraph>
  )
}

export default SearchResults
