/* eslint-disable camelcase */
import React, { useReducer } from 'react'
import lunr from 'lunr'
import { FormattedMessage, IntlContext } from 'react-intl'
import tw, { styled } from 'twin.macro'
//
import Icons from '~storybook/icons'
import useDebounce from '~utils/debounce'
import InputField from '~storybook/form/input'
import useLunr from './use-lunr'

require('lunr-languages/lunr.stemmer.support')(lunr)
require('lunr-languages/lunr.fr')(lunr)
require('lunr-languages/lunr.de')(lunr)
require('lunr-languages/lunr.nl')(lunr)

require('./lunr.unicodeNormalizer')(lunr)

const SectionTitle = styled.h5`
  ${tw`text-lg font-medium text-c-search-generic-text`}
`

const IconButton = styled.button`
  ${tw`text-white font-medium py-3 px-4 rounded-r-sm transition-colors duration-200 flex justify-center min-w-button`}
  ${tw`bg-c-header-search-searchButton hover:bg-c-header-search-searchButtonHover focus:bg-c-header-search-searchButtonHover active:bg-c-header-search-searchButtonHover focus:outline-none px-3 min-w-0`}
  ${({ disabled }) => disabled && tw`opacity-40 cursor-default hover:bg-c-header-search-searchButtonDisabled`}

  width: 48px;
  height: 46px;
`

const InputWrapper = styled.div`
  width: calc(100%);
  display: flex;
`

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_INDEX':
      return { ...state, ...action.payload }
    case 'SET_QUERY':
      return { ...state, ...action.payload }
    default:
      throw new Error()
  }
}

const initialState = {
  index: null,
  language: null,
  search: null,
  loading: true,
  store: {}
}

const Search = ({ initialQuery, language, handleResults }) => {
  const intl = React.useContext(IntlContext)
  const [state, dispatch] = useReducer(reducer, { ...initialState, searchQuery: initialQuery })

  const { index, searchQuery, loading, store } = state

  const debouncedSearchQuery = useDebounce(searchQuery, 500)

  React.useEffect(() => {
    fetch(`/${language}-search-index.json`)
      .then((resp) => resp.json())
      .then((data) => {
        if (data?.index && data?.store) {
          const newIndex = lunr.Index.load(data.index)

          dispatch({
            type: 'SET_INDEX',
            payload: { language, index: newIndex, store: data.store, loading: false }
          })
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e)
      })
  }, [language, initialQuery])

  const results = useLunr(debouncedSearchQuery, index, store)

  React.useEffect(() => {
    if (index) {
      handleResults({ searchQuery: debouncedSearchQuery, results })
    }
  }, [results])

  return (
    <div>
      <SectionTitle>{intl?.formatMessage({ id: 'search.search' })}</SectionTitle>
      <div className="flex flex-row mx-auto mt-3 pb-10">
        {loading ? (
          <FormattedMessage id="search.loading" />
        ) : (
          <>
            <InputWrapper>
              <InputField
                search
                field={{
                  name: 'search',
                  placeholder: intl.formatMessage({ id: 'search.placeholder' }),
                  value: searchQuery
                }}
                onChange={(query) => dispatch({ type: 'SET_QUERY', payload: { searchQuery: query } })}
              />
              <IconButton disabled>
                <Icons.Search />
              </IconButton>
            </InputWrapper>
          </>
        )}
      </div>
    </div>
  )
}

export default Search
