/* eslint-disable consistent-return */
/* eslint-disable no-console */
import { useMemo } from 'react'
import { Index } from 'lunr'

const normalize = (str) => {
  const regexpReplacements = {
    a: /[àáâãäå]/g,
    e: /[èéêë]/g,
    i: /[ìíîï]/g,
    o: /[òóôõöő]/g,
    u: /[ùúûüű]/g,
    y: /[ýŷÿ]/g,
    n: /ñ/g,
    c: /[ç]/g,
    s: /ß/g,
    // eslint-disable-next-line no-useless-escape
    '': /['!"#$%&\\()\*+,-./:;<=>?@[\]^_`{|}~]/g,
    ' ': /\s+/g
  }
  if (str) {
    str = str.replace(/[\u0300-\u036f]/g, '').toLowerCase()
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(regexpReplacements)) {
      str = str.replace(regexpReplacements[key], key)
    }
  }

  return str === ' ' ? '' : str
}

const useLunr = (query, rawIndex, rawStore, limit = 100) => {
  const index = useMemo(() => {
    if (rawIndex === undefined || rawIndex === null) {
      return
    }
    if (rawIndex instanceof Index) return rawIndex
    if (typeof rawIndex === 'string') return Index.load(JSON.parse(rawIndex))
    if (typeof rawIndex === 'object') return Index.load(rawIndex)

    console.error('Invalid index provided. Please provide an instance of Lunr.Index or exported JSON or string index.')
  }, [rawIndex])

  const store = useMemo(() => {
    if (typeof rawStore === 'string') return JSON.parse(rawStore)

    return rawStore
  }, [rawStore])

  return useMemo(() => {
    if (!query || !index) return []

    const results = typeof query === 'string' ? index.search(normalize(query)) : index.query(normalize(query))

    if (store) return results.map(({ ref }) => store[ref])?.slice(0, limit)

    return results
  }, [query, index, store])
}

export default useLunr
